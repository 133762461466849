
  import { Component, Prop, Vue, Watch } from "vue-property-decorator";

  @Component({
    components: {
      Spinner: () => import("@/components/general/spinner.vue"),
    },
  })
  export default class ItemRow extends Vue {
    @Prop() to?: Location | string;
    @Prop({ default: () => "link" }) mode?: "link" | "accordion";
    @Prop() isOpen?: boolean;
    @Prop() isLoading?: boolean;

    open = false;
    hovering = false;

    mounted() {
      this.open = this.isOpen || false;
    }

    handleClick(Event: Event) {
      if (this.mode === "accordion") {
        if (this.isOpen === undefined) {
          this.open = !this.open;
        }
      }

      this.$emit("click", Event);
    }

    /**
     * Check if the mode is actual clickable link
     *
     * @returns { boolean }
     */
    get isClickableLink() {
      return this.mode === "link" && this.to;
    }

    get showHoverShadow() {
      return this.hovering && (this.isClickableLink || this.mode === "accordion");
    }

    @Watch("isOpen")
    onOpenChange() {
      this.open = this.isOpen || false;
    }
  }
